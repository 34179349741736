import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TrulySecurePage = () => (
  <Layout>
    <SEO title="Truly secure voting system" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <div>
          <div>
            <div>
              <div>
                <h1>In an election, participating stakeholders may question its legitimacy. It is not enough to do things correctly, you must be able to prove it.</h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <div>
                <p>Most online voting systems claim to be secure referring to cybersecurity and resistance to cyberattacks. However, this is not enough. A voting system is secure in the sense that we can trust that the results of an election are fair and correct while protecting voters’ privacy. This is challenging because a voter must be able to trace the effect of her vote on the result, while on the other hand privacy requires that a vote cannot be traced back from the result to a voter.</p>
                <h3>End-to-end verifiability</h3>
                <p>Not only must the system operate correctly and election results must be fair, but it must be possible for participants and external observers to certify this unequivocally. nVotes auditable voting platform makes it possible for voters to ensure that:</p>
                <ul>
                  <li>Their choice was correctly encoded on the ballot by the system (cast-as-intended)</li>
                  <li>Their ballot was received the way they cast it (recorded-as-cast)</li>
                  <li>Their ballot counts as received (tallied-as-recorded)</li>
                </ul>
                <h3>Privacy</h3>
                <p>nVotes keeps ballots truly secret so that a vote does not identify a voter and any traceability between the voter and its vote is removed. Not even the administrators of the voting system or anyone with privileged access to hardware/software can violate this privacy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Layout>
)

export default TrulySecurePage
